<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Vehículos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Orion</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">Vehículos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Vehículos</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#Documentos"
                      v-if="form.id"
                      >Documentos</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#Estado"
                      v-if="form.id"
                      >Estado</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body pb-0">
                      <div class="row">
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Placa</label>
                                <input
                                  type="text"
                                  v-model="form.placa"
                                  class="form-control form-control-sm"
                                  id="numero_documento"
                                  :class="
                                    $v.form.placa.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  disabled
                                  @change="datosVehiculo(form.placa)"
                                />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="form-group">
                                <label>Area</label>
                                <v-select
                                  :disabled="bloquear || acto == 2"
                                  class="form-control form-control-sm p-0"
                                  v-model="form.areaobj"
                                  placeholder="Areas"
                                  label="descripcion"
                                  :class="[
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                    $v.form.area.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                  ]"
                                  :options="listasForms.areas"
                                  @input="selectArea()"
                                >
                                </v-select>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="form-group">
                                <label>Email</label>
                                <input
                                  v-model="form.email"
                                  :disabled="bloquear || acto == 2"
                                  type="email"
                                  class="form-control form-control-sm"
                                  id="email"
                                  :class="
                                    $v.form.email.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Sitio</label>
                                <v-select
                                  class="form-control form-control-sm p-0"
                                  v-model="form.sitio"
                                  :disabled="bloquear || acto == 2"
                                  placeholder="Sitio"
                                  label="nombre"
                                  :options="listasForms.sitios"
                                  :class="[
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                    $v.form.sitio_id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                  ]"
                                  @input="selectSitio()"
                                >
                                </v-select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Clase Vehículo</label>
                                <v-select
                                  class="form-control form-control-sm p-0"
                                  v-model="form.clase_vehiculoobj"
                                  :disabled="bloquear || acto == 2"
                                  placeholder="Clase Vehículos"
                                  label="descripcion"
                                  :options="listasForms.clases_vehiculos"
                                  :class="[
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                    $v.form.clase_vehiculo.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                  ]"
                                  @input="selectClaseVehiculo()"
                                >
                                </v-select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Operación Vehículo</label>
                                <v-select
                                  class="form-control form-control-sm p-0"
                                  v-model="form.operacion_vehiculo"
                                  :disabled="bloquear || acto == 2"
                                  placeholder="Operación"
                                  label="descripcion"
                                  :options="listasForms.operacion_vehiculos"
                                  :class="[
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                    $v.form.operacion_vh.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                  ]"
                                  @input="selectOperacionVh()"
                                >
                                </v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Activo</label>
                                <v-select
                                  class="form-control form-control-sm p-0"
                                  v-model="form.activoobj"
                                  :disabled="bloquear || acto == 2"
                                  placeholder="Activos"
                                  label="descripcion"
                                  :class="[
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                    $v.form.activo.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                  ]"
                                  :options="listasForms.activos"
                                  @input="selectActivo()"
                                >
                                </v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Fecha Ingreso</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  id="fecha_nacimiento"
                                  v-model="form.fecha_ingreso"
                                  :disabled="bloquear || acto == 2"
                                  @input="validaFechas()"
                                  :class="
                                    $v.form.fecha_ingreso.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Fecha Salida</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  id="fecha_nacimiento"
                                  v-model="form.fecha_salida"
                                  :disabled="bloquear || acto == 2"
                                  @input="validaFechas()"
                                  :class="
                                    $v.form.fecha_salida.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="num_lincencia_conduccion"
                                  >Modelo</label
                                >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  v-model="form.modelo"
                                  :disabled="bloquear || acto == 2"
                                  :class="
                                    $v.form.modelo.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Empresa</label>
                                <v-select
                                  class="form-control form-control-sm p-0"
                                  v-model="form.empresaobj"
                                  :disabled="bloquear || acto == 2"
                                  placeholder="Empresa"
                                  label="descripcion"
                                  :options="listasForms.empresas"
                                  :class="[
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                    $v.form.empresa.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                  ]"
                                  @input="selectEmpresa()"
                                >
                                </v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Marca</label>
                                <v-select
                                  class="form-control form-control-sm p-0"
                                  v-model="form.marcaobj"
                                  :disabled="bloquear || acto == 2"
                                  placeholder="Marcas"
                                  label="descripcion"
                                  :options="listasForms.marcas"
                                  :class="[
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                    $v.form.marca.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                  ]"
                                  @input="selectMarca()"
                                >
                                </v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="clase_vehiculo"
                                  >Tipo Servicio Placa</label
                                >
                                <select
                                  class="form-control form-control-sm"
                                  v-model="form.tipo_servicio_placa"
                                  :class="
                                    $v.form.tipo_servicio_placa.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  :disabled="bloquear || acto == 2"
                                  placeholder="Seleccione"
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    :value="opt.numeracion"
                                    v-for="opt in listasForms.tipo_servicio_placa"
                                    :key="opt.id"
                                  >
                                    {{ opt.descripcion }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Observaciones</label>
                                <textarea
                                  v-model="form.observaciones"
                                  :disabled="bloquear || acto == 2"
                                  cols="30"
                                  rows="3"
                                  class="form-control form-control-sm"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3" v-if="form.id">
                          <label for="imagen">Foto Vehículo</label>
                          <div
                            class="form-group"
                            v-if="form.link_fotografia == null"
                          >
                            <input
                              :disabled="bloquear || acto == 2"
                              type="file"
                              class="form-control-file"
                              accept="image/x-png,image/gif,image/jpeg"
                              @change="obtenerImagen"
                            />
                          </div>
                          <figure class="form-group" v-if="miniLogo">
                            <div class="button-container">
                              <img
                                :src="miniLogo"
                                alt="Logo"
                                width="236"
                                height="125"
                                class="rounded mx-auto d-block"
                              />
                              <a
                                @click="destroyImage()"
                                :disabled="bloquear || acto == 2"
                                id="BotonEliminar"
                                v-if="form.link_foto"
                                href="#"
                                >Eliminar <i class="fas fa-window-close"></i
                              ></a>
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Documentos" v-if="form.id">
                    <SolicitudVehiculoDocumento
                      v-if="form.id != null"
                      ref="SolicitudVehiculoDocumento"
                    />
                  </div>
                  <div class="tab-pane fade" id="Estado" v-if="form.id">
                    <div
                      v-if="$store.getters.can('hse.solicitudesVehiculos.edit')"
                      class="card-body pb-0"
                    >
                      <div class="row">
                        <div class="form-group col-md-2">
                          <label>Fecha Vencimiento</label>
                          <input
                            type="date"
                            v-model="fecha_vencimiento"
                            :disabled="bloquear || acto == 2"
                            class="form-control form-control-sm"
                            @change="validacionFechaVencimiento()"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label>Estado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.estado"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="acto == 2"
                            @change="validarEstado()"
                          >
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                          <span
                            v-if="form.Estado"
                            class="badge"
                            :class="[
                              estado_anterior == 1
                                ? 'badge-secondary'
                                : estado_anterior == 2
                                ? 'badge-success'
                                : estado_anterior == 3
                                ? 'badge-danger'
                                : estado_anterior == 4
                                ? 'bg-olive'
                                : estado_anterior == 5
                                ? 'badge-warning'
                                : estado_anterior == 6
                                ? 'badge-info'
                                : estado_anterior == 7
                                ? 'bg-navy'
                                : estado_anterior == 8
                                ? 'bg-lime'
                                : '',
                            ]"
                            >Estado Actual: {{ form.Estado }}</span
                          >
                        </div>
                        <div class="form-group col-md-6">
                          <label>Observaciones</label>
                          <textarea
                            v-model="observaciones_estado"
                            class="form-control form-control-sm"
                            style="resize: vertical"
                            :disabled="acto == 2"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table
                        v-if="historico.length > 0"
                        class="table table-bordered table-hover table-sm m-0"
                        style="font-size: 0.9em"
                      >
                        <thead class="bg-light">
                          <tr class="text-center">
                            <th class="col-2">Fecha</th>
                            <th class="col-2">Estado Anterior</th>
                            <th class="col-2">Estado Nuevo</th>
                            <th class="col-3">Observación</th>
                            <th>Realizado por</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="hist in historico" :key="hist.id">
                            <td>{{ hist.fecha_hora }}</td>
                            <td class="text-center">
                              <span
                                v-if="hist.estado_anterior"
                                class="badge"
                                :class="[
                                  hist.estado_anterior == 1
                                    ? 'badge-secondary'
                                    : hist.estado_anterior == 2
                                    ? 'badge-success'
                                    : hist.estado_anterior == 3
                                    ? 'badge-danger'
                                    : hist.estado_anterior == 4
                                    ? 'bg-olive'
                                    : hist.estado_anterior == 5
                                    ? 'badge-warning'
                                    : hist.estado_anterior == 6
                                    ? 'badge-info'
                                    : hist.estado_anterior == 7
                                    ? 'bg-navy'
                                    : hist.estado_anterior == 8
                                    ? 'bg-lime'
                                    : '',
                                ]"
                                >{{ hist.nEstadoAnt }}</span
                              >
                            </td>
                            <td class="text-center">
                              <span
                                class="badge"
                                :class="[
                                  hist.estado_nuevo == 1
                                    ? 'badge-secondary'
                                    : hist.estado_nuevo == 2
                                    ? 'badge-success'
                                    : hist.estado_nuevo == 3
                                    ? 'badge-danger'
                                    : hist.estado_nuevo == 4
                                    ? 'bg-olive'
                                    : hist.estado_nuevo == 5
                                    ? 'badge-warning'
                                    : hist.estado_nuevo == 6
                                    ? 'badge-info'
                                    : hist.estado_nuevo == 7
                                    ? 'bg-navy'
                                    : hist.estado_nuevo == 8
                                    ? 'bg-lime'
                                    : '',
                                ]"
                                >{{ hist.nEstadoNuevo }}</span
                              >
                            </td>
                            <td>
                              <small>{{ hist.observacion }}</small>
                            </td>
                            <td>{{ hist.user ? hist.user.name : "" }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else class="row justify-content-center">
                        <div class="alert alert-warning mt-3">
                          <h5>
                            <i class="icon fas fa-exclamation-triangle"></i>
                            Atención!
                          </h5>
                          No existe ningún cambio de estado registrado.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    v-show="!$v.form.$invalid"
                    class="btn bg-primary col-md-1"
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button @click="back()" class="btn bg-secondary col-md-1">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import SolicitudVehiculoDocumento from "./SolicitudVehiculoDocumento";
import Loading from "../../../../components/Loading";
import {
  required,
  helpers,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
const alphaNumeric = helpers.regex("alpha", /^[a-z A-Z 0-9 Ññ]*$/);

export default {
  name: "SolicitudVehiculoForm",
  components: {
    Loading,
    vSelect,
    SolicitudVehiculoDocumento,
  },

  data() {
    return {
      cargando: false,
      acto: "",
      estado_anterior: null,
      fecha_ven_anterior: null,
      observaciones_estado: "",
      form: {
        id: null,
        vehiculo_id: null,
        operacion_vehiculo: {},
        operacion_vh: null,
        placa: null,
        empresa: null,
        empresaobj: {},
        email: null,
        activo: null,
        activoobj: {},
        area: null,
        areaobj: {},
        sitio: {},
        sitio_id: null,
        fecha_ingreso: null,
        fecha_salida: null,
        marca: null,
        marcaobj: {},
        modelo: null,
        clase_vehiculo: null,
        clase_vehiculoobj: {},
        tipo_vehiculo: {},
        tipo_vehiculo_id: null,
        observaciones: "",
        observaciones_estado: "",
        foto: null,
        link_fotografia: null,
        estado: null,
        Estado: null,
        tipo_servicio_placa: null,
        inspeccion_id: null,
        estado_inspeccion: null,
      },
      historico: [],
      fecha_menor: null,
      user_id: null,
      modelo: null,
      bloquear: null,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      metodo: "",
      transportadora: {},
      lineaNegocio: {},
      fecha_vencimiento: null,
      documentos_files: [],
      listasForms: {
        areas: [],
        sitios: [],
        operacion_vehiculos: [],
        activos: [],
        marcas: [],
        clases_vehiculos: [],
        empresas: [],
        tipos_vehiculos: [],
        vehiculos: [],
        estados: [],
        tipo_servicio_placa: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    form: {
      placa: {
        required,
        alphaNumeric,
        maxLength: maxLength(8),
        minLength: minLength(5),
      },
      email: {
        required,
        email,
      },
      area: {
        required,
      },
      sitio_id: {
        required,
      },
      fecha_ingreso: {
        required,
      },
      fecha_salida: {
        required,
      },
      empresa: {
        required,
      },
      activo: {
        required,
      },
      marca: {
        required,
      },
      modelo: {
        required,
      },
      operacion_vh: {
        required,
      },
      clase_vehiculo: {
        required,
      },
      estado: {
        required,
      },
      tipo_servicio_placa: {
        required,
      },
    },
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      await this.getAreas();
      await this.getSitios();
      await this.getOperacionVehiculos();
      await this.getActivos();
      await this.getMarcas();
      await this.getClasesVehiculos();
      await this.getEmpresas();
      await this.getTipoVh();
      await this.getTipoServicioPlaca();
      this.acto = this.$route.params.acto;

      let me = this;
      await axios
        .get("api/hse/HseSolicitudesVehiculos/" + await this.$route.params.id)
        .then(async(response) => {
          this.cargando = false;
          this.form = response.data;

          let documentos_files = [];
          this.historico = [];
          if (response.data) {
            documentos_files = response.data["documentos_files"];
            this.historico = response.data["historicos_estados"];
          }

          let fechas = [];
          if (documentos_files) {
            documentos_files.forEach((element) => {
              fechas.push(element.fecha_vencimiento);
            });
            me.fecha_menor = moment("0000-00-00").format("YYYY-MM-DD");
            fechas.forEach(function(fecha) {
              if (fecha < me.fecha_menor && fecha != "0000-00-00") {
                me.fecha_menor = fecha;
              }
            });
            me.fecha_vencimiento = me.fecha_menor;
          }

          this.fileFotografia = this.form.link_foto
            ? this.form.link_foto
            : null;

          // Se toma el estado de la solicitud para validar la gestion a realizar en el módulo
          this.estado_anterior = this.form.estado;
          this.getListaEstados(this.estado_anterior);

          if (this.form.empresa) {
            this.form.empresa_id = this.form.empresa;
            let empresa = this.listasForms.empresas.find(
              (e) => e.numeracion === this.form.empresa
            );
            this.form.empresaobj = empresa;
          }
          if (this.form.activo) {
            this.form.activo_id = this.form.activo;
            let activo = this.listasForms.activos.find(
              (a) => a.numeracion === this.form.activo
            );
            this.form.activoobj = activo;
          }
          if (this.form.area) {
            this.form.area_id = this.form.area;
            let area = this.listasForms.areas.find(
              (a) => a.numeracion === this.form.area
            );
            this.form.areaobj = area;
          }
          if (this.form.sitio_id) {
            let sit = this.listasForms.sitios.find(
              (s) => s.id === this.form.sitio_id
            );
            this.form.sitio = sit;
          }
          if (this.form.operacion_vh) {
            this.form.operacion_vehiculo_id = this.form.operacion_vh;
            let operacion = this.listasForms.operacion_vehiculos.find(
              (o) => o.numeracion === this.form.operacion_vh
            );
            this.form.operacion_vehiculo = operacion;
          }
          if (this.form.marca) {
            this.form.marca_id = this.form.marca;
            let marca = this.listasForms.marcas.find(
              (m) => m.numeracion === this.form.marca
            );
            this.form.marcaobj = marca;
          }
          if (this.form.clase_vehiculo) {
            this.form.clase_vehiculo_id = this.form.clase_vehiculo;
            let clase = this.listasForms.clases_vehiculos.find(
              (c) => c.numeracion === this.form.clase_vehiculo
            );
            this.form.clase_vehiculoobj = clase;
          }
          if (this.form.tipo_vehiculo_id) {
            let tipo = this.listasForms.tipos_vehiculos.find(
              (t) => t.id === this.form.tipo_vehiculo_id
            );
            this.form.tipo_vehiculo = tipo;
          }
          /* if (this.form.estado) {
            if (this.form.estado && this.form.estado == 8) {
              this.bloquear = true;
            }
          } */
          if (this.form.link_foto) {
            this.miniLogo = this.uri_docs + this.form.link_foto;
          }
          if (
            this.form.fecha_fin_tarjeton != null &&
            this.form.fecha_fin_tarjeton != "0000-00-00"
          ) {
            this.fecha_vencimiento = this.form.fecha_fin_tarjeton;
          }
          this.fecha_ven_anterior = this.fecha_vencimiento;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    validacionFechaVencimiento() {
      if (this.fecha_vencimiento > this.fecha_menor) {
        this.$swal({
          icon: "error",
          title: `NO ES POSIBLE ASIGNAR UNA FECHA MAYOR A  ` + this.fecha_menor,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.fecha_vencimiento = this.fecha_menor;
      }
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    async getSitios() {
      await axios.get("/api/hse/listaSitios").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    async getOperacionVehiculos() {
      await axios.get("/api/lista/167").then((response) => {
        this.listasForms.operacion_vehiculos = response.data;
      });
    },

    async getActivos() {
      await axios.get("/api/lista/117").then((response) => {
        this.listasForms.activos = response.data;
      });
    },

    async getMarcas() {
      await axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    async getClasesVehiculos() {
      await axios.get("/api/lista/163").then((response) => {
        this.listasForms.clases_vehiculos = response.data;
      });
    },

    async getEmpresas() {
      await axios.get("/api/lista/164").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    async getTipoVh() {
      await axios
        .get("api/hse/HseSolicitudesVehiculos/listaTipoVh")
        .then((response) => {
          this.listasForms.tipos_vehiculos = response.data;
        });
    },

    getEstados(lista = []) {
      axios
        .get("/api/lista/175", {
          params: { in_number: lista },
        })
        .then((response) => {
          this.listasForms.estados = response.data;
        });
    },

    async getTipoServicioPlaca() {
      await axios.get("/api/lista/181").then((response) => {
        this.listasForms.tipo_servicio_placa = response.data;
      });
    },

    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    selectEmpresa() {
      this.form.empresa = null;
      if (this.form.empresaobj) {
        this.form.empresa = this.form.empresaobj.numeracion;
      }
    },

    selectActivo() {
      this.form.activo = null;
      if (this.form.activoobj) {
        this.form.activo = this.form.activoobj.numeracion;
      }
    },

    selectOperacionVh() {
      this.form.operacion_vh = null;
      if (this.form.operacion_vehiculo) {
        this.form.operacion_vh = this.form.operacion_vehiculo.numeracion;
      }
    },

    selectClaseVehiculo() {
      this.form.clase_vehiculo = null;
      if (this.form.clase_vehiculoobj) {
        this.form.clase_vehiculo = this.form.clase_vehiculoobj.numeracion;
      }
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.form.sitio) {
        this.form.sitio_id = this.form.sitio.id;
      }
    },

    selectMarca() {
      this.form.marca = null;
      if (this.form.marcaobj) {
        this.form.marca = this.form.marcaobj.numeracion;
      }
    },

    selectArea() {
      this.form.area = null;
      if (this.form.areaobj) {
        this.form.area = this.form.areaobj.numeracion;
      }
    },

    selectTipoVh() {
      this.form.tipo_vehiculo_id = null;
      if (this.form.tipo_vehiculo) {
        this.form.tipo_vehiculo_id = this.form.tipo_vehiculo.id;
      }
    },

    envioCorreoAceptacion() {
      var m = this;
      axios
        .get("/api/hse/HseSolicitudesVehiculos/envioCorreoAceptacion", {
          params: {
            id: m.$route.params.id,
          },
        })
        .then(() => {
          m.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          m.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    /* saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/admin/conductores/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_fotografia = response.data.link_fotografia;
          this.cargando = false;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    }, */

    destroyImage() {
      this.miniLogo = null;
      this.fileFotografia = null;
    },

    validarEstado() {
      let me = this;
      if (me.form.estado == 2) {
        axios
          .get("api/hse/HseSolicitudesVehiculos/inspeccion/" + me.form.id)
          .then((response) => {
            if (response.data) {
              me.$swal({
                icon: "warning",
                title: `Se encontró una inspección vigente para esta solicitud.`,
                text:
                  "Se asiganará la inspección " +
                  response.data.inspeccion_id +
                  " a esta solicitud.",
                confirmButtonText: "Ok",
                showCancelButton: false,
              });
              me.fecha_vencimiento =
                me.fecha_vencimiento < response.data.fecha_vencimiento
                  ? me.fecha_vencimiento
                  : response.data.fecha_vencimiento;
              me.form.estado_inspeccion = response.data.estado_inspeccion;
              me.form.inspeccion_id = response.data.inspeccion_id;
            }
          });
      }
    },

    save() {
      let me = this;
      let formData = new FormData();
      me.cargando = true;
      let observacion_estado =
        me.estado_anterior != me.form.estado ? me.observaciones_estado : null;
      if (observacion_estado == null || observacion_estado == "") {
        observacion_estado = me.observaciones_estado
          ? me.observaciones_estado
          : me.fecha_ven_anterior != me.fecha_vencimiento &&
            me.estado_anterior == me.form.estado
          ? "Actualización de Fecha Vencimiento: " +
            me.fecha_ven_anterior +
            " por la fecha: " +
            me.fecha_vencimiento
          : "Actualización automática del sistema por cambios en la solicitud";
      }
      formData.append("id", me.form.id);
      formData.append(
        "vehiculo_id",
        me.form.vehiculo_id ? me.form.vehiculo_id : ""
      );
      formData.append("placa", me.form.placa);
      formData.append("email", me.form.email);
      formData.append("area", me.form.area);
      formData.append("activo", me.form.activo);
      formData.append("sitio_id", me.form.sitio_id);
      formData.append("operacion_vh", me.form.operacion_vh);
      formData.append("empresa", me.form.empresa);
      formData.append("fecha_ingreso", me.form.fecha_ingreso);
      formData.append("fecha_salida", me.form.fecha_salida);
      formData.append("marca", me.form.marca);
      formData.append("modelo", me.form.modelo);
      formData.append("clase_vehiculo", me.form.clase_vehiculo);
      formData.append("observaciones", me.form.observaciones);
      formData.append("observaciones_estado", observacion_estado);
      formData.append("link_fotografia", me.fileFotografia);
      formData.append("fecha_fin_tarjeton", me.fecha_vencimiento);
      formData.append("fecha_ven_anterior", me.fecha_ven_anterior);
      if (me.estado_anterior === 5 || me.estado_anterior === 3) {
        formData.append("estado", 6);
      } else {
        formData.append("estado", me.form.estado);
      }
      formData.append("estado_anterior", me.estado_anterior);
      formData.append("tipo_servicio_placa", me.form.tipo_servicio_placa);
       if (me.form.inspeccion_id != null) { 
        formData.append("estado_inspeccion", me.form.estado_inspeccion);
        formData.append("inspeccion_id", me.form.inspeccion_id);
       } 
      // if (me.form.estado == 2) {
      //   me.envioCorreoAceptacion();
      // }

      axios
        .post("api/hse/HseSolicitudesVehiculos", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          me.observaciones_estado = "";
          me.getIndex();
          me.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      me.cargando = false;
      /* } */
    },

    back() {
      return this.$router.replace("/Hse/SolicitudesVehiculos");
    },

    validaFechas() {
      if (this.form.fecha_ingreso && this.form.fecha_salida) {
        const fecha_menor = new Date(this.form.fecha_ingreso);
        const fecha_mayor = new Date(this.form.fecha_salida);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_salida = null;
          this.$swal({
            icon: "error",
            title: `La fecha de emisión no puede ser mayor a la fecha de vencimiento...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    estadoEditable(estado) {
      return estado == 6 || estado == 8 || estado == 2 ? true : false;
    },

    getListaEstados(estado) {
      let lista = [];
      switch (estado) {
        case 2:
          lista = [3, 2];
          break;

        case 6:
          lista = [8, 3, 6];
          break;

        case 8:
          lista = [2, 3, 8];
          break;

        case 4:
          lista = [4];
          break;

        default:
          lista = [100];
          break;
      }
      this.getEstados(lista);
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
